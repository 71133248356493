<template>
  <div>
    <div class="wrapper main-form-container">
      <div class="column column-1">
        <div class="rows-wrapper">
          <div class="rows">
            <div :class="contentTypeWrapperClasses">
              <MaterialSelect
                v-model="formData.contentType"
                v-bind="formFields.contentType"
                :options="optionsContentType"
                :withEmptyField="false"
              />
            </div>
            <div
              v-if="formData.contentType === 'feed'"
              class="select-wrapper"
            >
              <MaterialInput
                v-model="formData.feed"
                v-bind="formFields.feed"
                :requiredFromBack="true"
              />
            </div>
          </div>
          <div
            v-if="formData.contentType === 'folder'"
            class="folders"
          >
            <FoldersManagement
              :selectedValue="formData.mediaFolder"
              v-bind="formFields.mediaFolder"
              :label="'Source Folder'"
              :required="true"
              :commonError="foldError"
              :onChange="onFoldersChange"
            />
          </div>
          <div
            v-if="formData.contentType === 'categories'"
            class="categories-management-wrapper"
          >
            <CategoriesManagement
              v-if="formData.contentType === 'categories'"
              label="Select Categories"
              :selectedValue="formData.categories"
              :onChange="handleCategoryChange"
              :commonError="categoriesError"
              :required="true"
            />
          </div>
          <div
            v-if="isSortAndGroupOptionsAvailable"
            class="rows"
          >
            <div class="select-wrapper">
              <MaterialSelect
                v-model="formData.groupContent"
                v-bind="formFields.groupContent"
                :options="groupOptions"
                :withEmptyField="false"
              />
            </div>
            <div class="select-wrapper">
              <MaterialSelect
                v-model="formData.sortOption"
                v-bind="formFields.sortOption"
                :options="sortOptions"
                :withEmptyField="false"
              />
            </div>
          </div>
          <div class="rows">
            <div
              v-if="isExcludeContentTypesAvailable"
              class="select-wrapper"
            >
              <MediaTagManagement
                :key="`excludeContentTypes${formData.contentType}`"
                :selectedValue="formData.excludeContentTypes"
                :label="'Exclude Content Types'"
                :tagPlaceholder="''"
                :searchable="false"
                :onChange="handleExcludeContentTypesChange"
                :customOptions="contentTypesOptions"
                :isAudio="true"
              />
            </div>
          </div>
          <div
            v-if="!hasFolder && !hasFeed && !hasCategories"
            class="localization-warning-wrapper"
          >
            <inline-svg
              :src="iconWarning"
              class="svg-icon"
            >
            </inline-svg>
            <span class="localization-warning">
              Page Tabs will be available after the Data Source has been saved
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSelect from '../../components/inputs/MaterialSelect.vue';
import MaterialInput from '../../components/inputs/MaterialInput.vue';
import iconWarning from '../../assets/icons/icon-warning-localization.svg';
import FoldersManagement from '../../components/FoldersManagement.vue';
import CategoriesManagement from '../../components/inputs/CategoriesManagement.vue';
import MediaTagManagement from '../../components/media/MediaTagManagement.vue';

export default {
  name: 'GridDataSource',
  components: {
    FoldersManagement,
    MaterialInput,
    MaterialSelect,
    CategoriesManagement,
    MediaTagManagement,
  },
  props: {
    groupOptions: {
      type: Array,
      default: () => [],
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    contentTypesOptions: {
      type: Array,
      default: () => [],
    },
    optionsContentType: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    formFields: {
      type: Object,
      default: () => {},
    },
    hasFeed: {
      type: String,
      default: '',
    },
    hasFolder: {
      type: String,
      default: '',
    },
    hasCategories: {
      type: String,
      default: '',
    },
    foldError: {
      type: String,
      default: '',
    },
    categoriesError: {
      type: String,
      default: '',
    },
    excludeContentTypesError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconWarning,
    };
  },
  computed: {
    isExcludeContentTypesAvailable() {
      return this.formData.groupContent === 'none' && ['folder', 'categories'].includes(this.formData.contentType) && this.contentTypesOptions.length > 0;
    },
    isSortAndGroupOptionsAvailable() {
      return ['folder', 'categories'].includes(this.formData.contentType);
    },
    contentTypeWrapperClasses() {
      return {
        'select-wrapper': true,
        'select-wrapper-full': ['folder', 'categories'].includes(this.formData.contentType),
      };
    },
  },
  methods: {
    onFoldersChange(value) {
      this.formData.mediaFolder = value ? value.code : value;
      this.foldError = '';
    },
    handleCategoryChange(value) {
      this.formData.categories = value;
      this.categoriesError = '';
    },
    handleExcludeContentTypesChange(value) {
      this.formData.excludeContentTypes = value.map((excludeItem) => ({
        id: excludeItem,
        name: excludeItem,
      }));
      this.excludeContentTypesError = '';
    },
  },
};
</script>

<style scoped>
.rows {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.select-wrapper {
  width: 300px;
}

.localization-warning-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #F2C94C;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.localization-warning {
  font-size: 14px;
  color: #F2C94C;
  padding-left: 8px;
}

.folders {
  margin-bottom: 20px;
}

.select-wrapper-full {
  width: 100%;
}

.categories-management-wrapper {
  margin-bottom: 20px;
  margin-top: 5px;
}
</style>
